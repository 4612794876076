import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import _ from 'lodash';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import {
  getDocumentos,
  setDocumentoId,
  setDocumento,
  deleteDocumento,
} from '../../actions/documento';
import { getExpenses } from '../../actions/financial';
import tableI18n from '../TableLocalization';
import DocumentoForm from './documento';
import ConfirmDialog from '../commons/ConfirmDialog';
import SindilegisBar from '../commons/SindilegisBar';
import { storage } from '../../firebase';
import styles from './styles';
import Loading from '../commons/Loading';
import * as roles from '../../constants/roles';
import * as routes from '../../constants/routes';
import { formataData } from '../Financial/util';
import MenuButton from '../commons/MenuButton';
import { getRoutes } from '../commons/util';
import ButtonComponent from '../commons/ButtonComponent';

const Documentos = ({ classes, authUser, loading, enqueueSnackbar }) => {
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(() => () => { });
  const [confirmMessage, setConfirmMessage] = useState('');

  const documentos = useSelector((state) => state.documento.documentos);
  const dispatch = useDispatch();
  const location = useLocation();

  const canEdit = _.includes(authUser.roles, roles.ADMIN_FINANCEIRO);
  const currentPath = location.pathname;
  const buttonRoutes = getRoutes(routes, currentPath);

  useEffect(() => {
    dispatch(getExpenses());
    dispatch(getDocumentos());
  }, [dispatch]);

  const handleDocumentoClickOpen = () => setOpen(true);

  const handleDocumentoClose = (reload) => {
    dispatch(setDocumento(null));
    dispatch(setDocumentoId(null));
    setOpen(false);
    if (reload) {
      reloadDocumentos();
    }
  };

  const handleConfirmOpen = (action, message) => {
    setConfirmAction(() => action);
    setConfirmMessage(message);
    setConfirmOpen(true);
  };

  const handleConfirmClose = (confirm) => {
    if (confirm) {
      confirmAction();
    }
    setConfirmOpen(false);
  };

  const reloadDocumentos = () => {
    dispatch(getDocumentos());
  };

  const removeDocumento = (id) => {
    dispatch(deleteDocumento(id));
  };

  const styleCanotEdit = !canEdit ? { padding: 20 } : {};
  const title = canEdit ? 'Gerenciar contratos e documentos auxiliares' : 'Documentos';

  return (
    <div>
      <SindilegisBar />
      <Loading loading={loading} />
      <Container maxWidth="xl">
        <MenuButton buttonsRoutes={buttonRoutes} location={location} />
        {canEdit && (
          <Grid
            container
            item
            md={12}
            justifyContent="flex-end"
            spacing={1}
            className={classes.areaOperacoes}
          >
            <Grid item>
              <ButtonComponent variant="insert" onClick={handleDocumentoClickOpen}>
                Cadastrar documento
              </ButtonComponent>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} md={12} style={styleCanotEdit}>
          <Typography variant="h6" className={classes.title} />
          <div className={classes.demo}>
            <MaterialTable
              columns={[
                {
                  title: 'Nome do arquivo',
                  field: 'nome',
                  render: (row) => (
                    <Link
                      component="button"
                      variant="body2"
                      onClick={
                        row.anexo?.url
                          ? () => storage.downloadAnexo(row.anexo.url)
                          : () => enqueueSnackbar('Não há nenhum anexo vinculado ao documento.', { variant: 'warning' })
                      }
                    >
                      {row.nome}
                    </Link>
                  ),
                },
                {
                  title: 'Data de carregamento',
                  field: 'data_carregamento',
                  render: (row) => formataData(row.data_carregamento),
                },
                {
                  title: 'Início da vigência',
                  field: 'data_inicio_vigencia',
                  render: (row) => formataData(row.data_inicio_vigencia),
                },
                {
                  title: 'Fim da vigência',
                  field: 'data_fim_vigencia',
                  render: (row) => formataData(row.data_fim_vigencia),
                },
                {
                  title: 'Vigente',
                  field: 'vigente',
                },
                { title: 'Usuário', field: 'email' },
              ]}
              data={documentos}
              title={title}
              actions={
                canEdit
                  ? [
                    {
                      icon: 'edit',
                      tooltip: 'Editar',
                      onClick: (event, row) =>
                        dispatch(setDocumentoId(row.id)) && handleDocumentoClickOpen(),
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Excluir',
                      onClick: (event, row) =>
                        handleConfirmOpen(
                          () => removeDocumento(row.id),
                          'Confirma a exclusão permanente do documento?'
                        ),
                    },
                  ]
                  : []
              }
              options={{
                actionsColumnIndex: -1,
              }}
              localization={tableI18n}
            />
          </div>
        </Grid>
        <DocumentoForm
          open={open}
          handleClose={handleDocumentoClose}
          email={authUser.email}
        />
        <ConfirmDialog
          open={confirmOpen}
          message={confirmMessage}
          onClose={handleConfirmClose}
        />
      </Container>
    </div>
  );
};

Documentos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withSnackbar
)(Documentos);
