import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import SignInPage from './SignIn';
import PasswordForgetPage from './PasswordForget';
import DashboardFiliados from './DashboardFiliados';
import AccountPage from './Account';

import * as routes from '../constants/routes';
import * as roles from '../constants/roles';

import withRoot from '../withRoot';
import Financial from './Financial';
import Beneficiarios from './Beneficiarios';
import Expenses from './Financial/lista_despesas';
import RelatorioDespesas from './Financial/relatorio_despesas';
import Receitas from './Financial/receitas';
import GraficosReceitas from './Financial/graficos_receitas';
import AprovarDespesas from './Financial/aprovar_despesas';
import Graficos from './Financial/graficos';
import Projecao from './Financial/projecao';
import AcompanhamentoOrcamentario from './Financial/acompanhamento_orcamentario';
import Transparencia from './Financial/Transparencia';
import Roles from './Usuarios';
import Reports from './Reports';
import RelatorioProcessos from './ProcessosList/relatorio';
import RelatorioDependentes from './DependentList/relatorio';
import NotFoundPage from './NotFoundPage';
import ProtectedRoute from './Auth/ProtectedRoute';
import { AuthProvider } from './Auth/AuthContext';
import ItensDespesa from './ItensDespesa';
import ItensReceita from './ItensReceita';
import Eventos from './Eventos';
import Funcionarios from './Funcionarios';
import Documentos from './Documentos';
import Interacoes from './Interacoes';
import ItensInteracao from './ItensInteracao';
import HomeJuridico from './Juridico';
import Comunicacao from './Comunicacao';
import Filiado from './Filiado';
import MeusProcessos from './Filiado/MeusProcessos/meusProcessos';
import Filiados from './Filiados';
import Atendimentos from './Juridico/Atendimento/atendimentos';
import Processos from './Juridico/Processo/processos';
import ObjetoProcesso from './Juridico/ObjetoProcesso';
import Suprimentos from './Suprimentos';
import PoliticoSindical from './PoliticoSindical';
import TemaList from './PoliticoSindical/TemaList';
import PainelDetalharTema from './PoliticoSindical/PainelDetalharTema';
import EscritorioJuridico from './EscritorioJuridico';
import ProfilePage from './ProfilePage';
import Escritorios from './Juridico/Escritorios';
import Satisfacao from './Satisfacao';
import PerfilAutoridades from './PerfilAutoridades';
import CardperfilAutoridadeForm from './PerfilAutoridades/CardperfilAutoridadeForm';
import Consignacao from './Consignacao';
import Sindex from './Sindex';
import Dashboard from './Dashboard';
import PowerBi from './PowerBi';
import Verificador from './Verificador';

const App = () => (
  <Router>
    <div>
      <AuthProvider>
        <Switch>
          <Route exact path={routes.SIGN_IN} component={SignInPage} />
          <Route exact path={routes.PASSWORD_FORGET} component={PasswordForgetPage} />
          <ProtectedRoute
            exact
            path={routes.DASHBOARD_FILIADOS}
            component={DashboardFiliados}
            roles={[roles.GESTOR_USUARIO, roles.VISUALIZADOR_USUARIO, roles.EDITOR_FILIADO]}
          />
          <ProtectedRoute
            exact
            path={routes.DASHBOARD_CASAS}
            component={Dashboard}
            roles={[roles.GESTOR_USUARIO, roles.VISUALIZADOR_USUARIO, roles.EDITOR_FILIADO]}
          />
          <ProtectedRoute exact path={routes.ACCOUNT} component={AccountPage} />
          <ProtectedRoute exact path={routes.PROFILE} component={ProfilePage} />
          <ProtectedRoute
            exact
            path={routes.FINANCIAL}
            component={Financial}
            roles={[roles.ADMIN_FINANCEIRO, roles.VISUALIZADOR_FINANCEIRO]}
          />
          <ProtectedRoute
            exact
            path={routes.EXPENSES}
            component={Expenses}
            roles={[roles.ADMIN_FINANCEIRO, roles.VISUALIZADOR_FINANCEIRO]}
          />
          <ProtectedRoute exact path={routes.APROVADOR} component={AprovarDespesas} />
          <ProtectedRoute
            exact
            path={routes.DESPESAS_A_LIQUIDAR}
            component={Expenses}
            roles={[roles.ADMIN_FINANCEIRO, roles.VISUALIZADOR_FINANCEIRO]}
          />
          <ProtectedRoute
            exact
            path={routes.RELATORIO_DESPESAS}
            component={RelatorioDespesas}
            roles={[roles.ADMIN_FINANCEIRO, roles.VISUALIZADOR_FINANCEIRO]}
          />
          <ProtectedRoute
            exact
            path={routes.RECEITAS}
            component={Receitas}
            roles={[roles.ADMIN_FINANCEIRO, roles.VISUALIZADOR_FINANCEIRO]}
          />
          <ProtectedRoute
            exact
            path={routes.GRAFICOS_RECEITAS}
            component={GraficosReceitas}
            roles={[roles.ADMIN_FINANCEIRO, roles.VISUALIZADOR_FINANCEIRO]}
          />
          <ProtectedRoute
            exact
            path={routes.ITENS_RECEITA}
            component={ItensReceita}
            roles={[roles.ADMIN_FINANCEIRO, roles.VISUALIZADOR_FINANCEIRO]}
          />
          <ProtectedRoute
            exact
            path={routes.ITENS_DESPESA}
            component={ItensDespesa}
            roles={[roles.ADMIN_FINANCEIRO, roles.VISUALIZADOR_FINANCEIRO]}
          />
          <ProtectedRoute
            exact
            path={routes.ACOMPANHAMENTO_ORCAMENTARIO}
            component={AcompanhamentoOrcamentario}
            roles={[roles.ADMIN_FINANCEIRO, roles.VISUALIZADOR_FINANCEIRO]}
          />
          <ProtectedRoute exact path={routes.BENEFICIARIOS} component={Beneficiarios} />
          <ProtectedRoute exact path={routes.EVENTOS} component={Eventos} />
          <ProtectedRoute
            exact
            path={routes.GRAFICOS}
            component={Graficos}
            roles={[roles.ADMIN_FINANCEIRO, roles.VISUALIZADOR_FINANCEIRO]}
          />
          <ProtectedRoute
            exact
            path={routes.PROJECAO}
            component={Projecao}
            roles={[roles.ADMIN_FINANCEIRO, roles.VISUALIZADOR_FINANCEIRO]}
          />
          <ProtectedRoute
            exact
            path={routes.DOCUMENTOS}
            component={Documentos}
            roles={[roles.ADMIN_FINANCEIRO, roles.VISUALIZADOR_FINANCEIRO]}
          />
          <ProtectedRoute
            exact
            path={routes.CONTRATOS}
            component={Documentos}
            roles={[roles.ADMIN_FINANCEIRO, roles.VISUALIZADOR_FINANCEIRO, roles.VISUALIZADOR_CONTRATOS]}
          />
          <ProtectedRoute
            exact
            path={routes.SUPRIMENTOS}
            component={Suprimentos}
            roles={[roles.ADMIN_FINANCEIRO, roles.VISUALIZADOR_CONTRATOS]}
          />
          <ProtectedRoute
            exact
            path={routes.ROLES}
            component={Roles}
            roles={[roles.GESTOR_SISTEMA]}
          />
          <ProtectedRoute
            exact
            path={routes.FILIADOS_RELATORIO}
            component={Reports}
            roles={[roles.GESTOR_USUARIO, roles.VISUALIZADOR_USUARIO, roles.EDITOR_FILIADO]}
          />
          <ProtectedRoute
            exact
            path={routes.RELATORIO_DEPENDENTES}
            component={RelatorioDependentes}
            roles={[roles.GESTOR_USUARIO, roles.VISUALIZADOR_USUARIO, roles.EDITOR_FILIADO]}
          />
          <ProtectedRoute
            exact
            path={routes.INTERACOES}
            component={Interacoes}
            roles={[roles.GESTOR_INTERACAO]}
          />
          <ProtectedRoute
            exact
            path={routes.ITENS_INTERACAO}
            component={ItensInteracao}
            roles={[roles.GESTOR_INTERACAO]}
          />
          <ProtectedRoute
            exact
            path={routes.FUNCIONARIOS}
            component={Funcionarios}
            roles={[roles.GESTOR_FUNCIONARIOS]}
          />
          <ProtectedRoute
            exact
            path={routes.ESCRITORIOS}
            component={Escritorios}
            roles={[roles.GESTOR_SISTEMA]}
          />
          <ProtectedRoute
            exact
            path={routes.JURIDICO_ATENDIMENTO}
            component={Atendimentos}
            roles={[roles.JURIDICO, roles.ESCRITORIO_JURIDICO, roles.VISUALIZADOR_JURIDICO]}
          />
          <ProtectedRoute
            exact
            path={routes.ESCRITORIO_JURIDICO}
            component={EscritorioJuridico}
            roles={[roles.JURIDICO, roles.ESCRITORIO_JURIDICO]}
          />
          <ProtectedRoute
            exact
            path={routes.JURIDICO}
            component={HomeJuridico}
            roles={[roles.JURIDICO, roles.ESCRITORIO_JURIDICO, roles.VISUALIZADOR_JURIDICO]}
          />
          <ProtectedRoute
            exact
            path={routes.JURIDICO_PROCESSOS_RELATORIO}
            component={RelatorioProcessos}
            roles={[roles.JURIDICO, roles.ESCRITORIO_JURIDICO, roles.VISUALIZADOR_FINANCEIRO]}
          />
          <ProtectedRoute
            exact
            path={routes.JURIDICO_PROCESSOS}
            component={Processos}
            roles={[roles.JURIDICO, roles.ESCRITORIO_JURIDICO, roles.VISUALIZADOR_JURIDICO]}
          />
          <ProtectedRoute
            exact
            path={routes.OBJETO_PROCESSO}
            component={ObjetoProcesso}
            roles={[roles.JURIDICO]}
          />
          <ProtectedRoute
            exact
            path={routes.COMUNICACAO}
            component={Comunicacao}
            roles={[roles.COMUNICACAO]}
          />
          <ProtectedRoute exact path={routes.FILIADO} component={Filiado} roles={[roles.FILIADO]} />
          <ProtectedRoute
            exact
            path={routes.PROCESSOS_FILIADO}
            component={MeusProcessos}
            roles={[roles.FILIADO]}
          />
          <ProtectedRoute exact path={routes.FILIADOS} component={Filiados} />
          <ProtectedRoute exact path={routes.POLITICO_SINDICAL_TEMAS} component={TemaList} />
          <ProtectedRoute
            exact
            path={routes.POLITICO_SINDICAL_TEMA}
            component={PainelDetalharTema}
          />
          <ProtectedRoute exact path={routes.POLITICO_SINDICAL} component={PoliticoSindical} />
          <ProtectedRoute exact path={routes.PESQUISA_DE_SATISFACAO} component={Satisfacao} />
          <ProtectedRoute exact path={routes.TRANSPARENCIA} component={Transparencia} />
          <ProtectedRoute exact path={routes.AUTORIDADES} component={PerfilAutoridades} />
          <ProtectedRoute
            exact
            path={routes.PERFIL_AUTORIDADE}
            component={CardperfilAutoridadeForm}
          />
          <ProtectedRoute
            exact
            path={routes.CONSIGNACAO}
            component={Consignacao}
            roles={[roles.CONSIGNACAO, roles.VISUALIZADOR_CONSIGNACAO]}
          />
          <ProtectedRoute exact path={routes.SINDEX} component={Sindex} />
          <ProtectedRoute exact path={routes.Verificador} component={Verificador} />
          <ProtectedRoute exact path={routes.POWER_BI} component={PowerBi} />
          <Route component={NotFoundPage} />
        </Switch>
      </AuthProvider>
    </div>
  </Router>
);

export default withRoot(App);
